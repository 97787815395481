<template>
	<div>
		<!-- 商家综合管理 -->
		<div class="queryBox">
			<div class="mb10">
				<el-select v-model="chooseTraderArr" multiple collapse-tags placeholder="请选择商家" size="mini" @change="chooseTrader">
					<el-option v-for="item in traderArr" :key="item.business_no" :label="item.business_name"
						:value="item.business_no">
					</el-option>
				</el-select>
				<el-select v-model="state" placeholder="请选择启停状态" size="mini" class="mr20" clearable>
					<el-option v-for="item in stateArr" :key="item.id" :label="item.label" :value="item.id"></el-option>
				</el-select>
				<el-select v-model="freeze" placeholder="请选择冻结状态" size="mini" class="mr20" clearable>
					<el-option v-for="item in freezeArr" :key="item.id" :label="item.label" :value="item.id">
					</el-option>
				</el-select>
				<el-select v-model="is_online" placeholder="请选择监控状态" size="mini" class="mr20" clearable>
					<el-option v-for="item in is_onlineArr" :key="item.id" :label="item.label" :value="item.id">
					</el-option>
				</el-select>
				<el-select v-model="locked" placeholder="请选择锁定状态" size="mini" class="mr20" clearable>
					<el-option v-for="item in lockedArr" :key="item.id" :label="item.label" :value="item.id">
					</el-option>
				</el-select>
				<!-- <template>
					<el-select v-model="bank" size="mini" class="mr20" clearable placeholder="请选择银行">
						<el-option v-for="(item,index) in bankListArr" :key="index" :label="item" :value="index"></el-option>
					</el-select>
					
				</template> -->
				<!-- 搜索前面   添加   监控状态，  锁定状态    冻结状态   的筛选，    然后进入页面  默认只显示状态都正常的卡 -->
				<el-button size="mini" type="primary" :icon="disLoading?'el-icon-loading':'el-icon-search'"	@click="queryDataAll()" :disabled="disLoading">搜索全部</el-button>
				<el-button size="mini" type="primary" :icon="disLoading?'el-icon-loading':'el-icon-search'"	@click="queryData()" :disabled="disLoading">搜索</el-button>
			</div>
			<div class="flexX flexcenter countTotal">
				<div class="mr100">

					<span>今日总充 </span>
					<span class="cdanger mr10">
						{{(countAll.money_in>10000?countAll.money_in/10000 :countAll.money_in) | tofixed}}
						{{countAll.money_in>10000?'万':'元'}}
					</span>
					<span class="mr10">|</span>
					<span>今日总提 </span>
					<span class="cdanger">
						{{(countAll.money_out>10000?countAll.money_out/10000 :countAll.money_out) | tofixed}}
						{{countAll.money_out>10000?'万':'元'}}
					</span>

				</div>
				<div>

					<span>商家余额 </span>
					<span class="cdanger mr10">
						{{(countAll.money>10000?countAll.money/10000 :countAll.money) | tofixed}}
						{{countAll.money>10000?'万':'元'}}
					</span>
					<span class="mr10">|</span>
					<span>商家冻结额度 </span>
					<span class="cdanger">
						{{(countAll.money_uncertain>10000?countAll.money_uncertain/10000 :countAll.money_uncertain) | tofixed}}
						{{countAll.money_uncertain>10000?'万':'元'}}
					</span>

				</div>

			</div>
			<div class="flexX flexcenter flexBetween operation">
				<!-- <span class="mr10">{{showTraderArr}}</span> -->
				 
				<div class="cardSet" v-loading="limitloading">
					<div class="group">
						<div class="item">
							<div class="label">当天累计收款上限</div>
							<div class="input-number">
								<span class="el-icon-caret-left"
									@click="limit('limitreceive','cut',receiveStep)"></span>
								<span class="num">{{limitreceive}}</span>
								<span class="el-icon-caret-right"
									@click="limit('limitreceive','add',receiveStep)"></span>
							</div>
						</div>
						<div class="item">
							<div class="label">配卡数</div>
							<div class="input-number">
								<span class="el-icon-caret-left" @click="limit('limitcard','cut',cardStep)"></span>
								<span class="num">{{limitcard}}</span>
								<span class="el-icon-caret-right" @click="limit('limitcard','add',cardStep)"></span>
							</div>
						</div>
					</div>
					<div class="group">
						<div class="item">
							<div class="label">可留存上限</div>
							<div class="input-number">
								<span class="el-icon-caret-left" @click="limit('limitSave','cut',saveStep)"></span>
								<span class="num">{{limitSave}}</span>
								<span class="el-icon-caret-right" @click="limit('limitSave','add',saveStep)"></span>
							</div>
						</div>
						<div class="item">
							<div class="label">轮巡上限</div>
							<div class="input-number">
								<span class="el-icon-caret-left"
									@click="limit('limitRoundrobin','cut',roundrobinStep)"></span>
								<span class="num">{{limitRoundrobin}}</span>
								<span class="el-icon-caret-right"
									@click="limit('limitRoundrobin','add',roundrobinStep)"></span>
							</div>
						</div>
					</div>
				</div>
				<div class="cardData">
					<!-- <div class="item" style="min-width: 120px;"><span>额度使用率百分比</span> <span>68%</span>
							<font class="cdanger">总：273.58万 已用：185.67万
							</font>
						</div> -->
					<div class="item"><span>累计收款上限百分比</span> <span>{{countAll.eve_used_money_ratio | tofixed}}%</span>
						<font class="cdanger">总：{{countAll.alipay_eve_used_money/10000 | tofixed}}万
							已用：{{countAll.money_in/10000 | tofixed}}万</font>
					</div>
					<div class="item"><span>可留存上限百分比</span> <span>{{countAll.keep_gold_ratio | tofixed}}%</span>
						<font class="cdanger">总：{{countAll.keep_gold/10000 | tofixed}}万
							已用：{{countAll.true_quota/10000 | tofixed}}万</font>
					</div>
				</div>

				<!-- <div class="averageInfos">
						<div class="details">
							<div class="item"><span>小计 <font>3.31万</font></span> <span>平均值 <font>2549</font></span> <span>进单数 <font>13</font></span>
								<span>成功率 <font>65%</font></span></div>
							<div class="item"><span>小计 <font>3.29万</font></span> <span>平均值 <font>1369</font></span> <span>出单数 <font>24</font></span>
								<span>拼单数 <font>0</font></span></div>
						</div>
						<div class="search">
							<div class="section">
								14:41 - 14:51
							</div>
							<div class="seconds"><span class="on">10分钟</span> <span class="">1小时</span> <span class="">6小时</span></div>
							<div class="times">
								<span class="el-icon-caret-left"></span>
								<div>14:51</div>
								<span class="el-icon-caret-right"></span>
							</div>
						</div>
					</div> -->
			</div>
			<div class="mt10">
			    <el-radio-group v-model="trader" size="small" @change="queryData">
			      <el-radio-button v-for="item in showTraderArr" :label="item.business_no" :disabled="disLoading" >{{item.business_name}}</el-radio-button>
			    </el-radio-group>
			</div>
		</div>
		<div class="databox">
			<template>
				<el-table :max-height="height" :data="tableData" border style="width: 100%;" size="mini"
					v-loading="loading">
					<el-table-column type="index" label="序号" width="100px" align="center">
						<template slot-scope="scope">
							<span v-if="!(scope.row.card == '总计' || scope.row.card == '当页总计')">
								{{scope.$index + 1}}
							</span>
							<span v-else="">{{scope.row.card}}</span>
						</template>
					</el-table-column>
					<el-table-column label="账号" width="180px" align="center">
						<template slot-scope="scope">
							<!-- <el-link type="primary" v-if="!(scope.row.card == '总计' || scope.row.card == '当页总计')"@click="showInfo(scope.row)"> -->
								<div>{{scope.row.true_name}}</div>
								<div>{{scope.row.account}}</div>
							<!-- </el-link> -->
							<span v-if="scope.row.card == '总计'">共 {{total}} 张</span>
						</template>
					</el-table-column>
					<el-table-column label="搬砖工" align="center">
						<template slot-scope="scope">
							<div v-if="!(scope.row.card == '总计' || scope.row.card == '当页总计')">
								<div>{{scope.row.name}}</div>
								<!-- <div class="csuccess">(开工)</div> -->
								<div :class="scope.row.carder_state==0?'csuccess':'cdanger'">
									{{scope.row.carder_state==0?'(开工)':'(停工)'}}</div>

							</div>

						</template>
					</el-table-column>
					<el-table-column min-width='130px' align="center">
						<template slot="header" slot-scope="scope">
							<div class="flexX flexcenter">
								<span class="mr10">搬砖工可用余额</span>
								<div>
									<div class="orderIcon" @click="orderData('can_used_money','ASC')"
										:class="orderField== 'can_used_money'&& orderBy=='ASC'?'cprimary':''"><i
											class="el-icon-caret-top"></i></div>
									<div class="orderIcon" @click="orderData('can_used_money','DESC')"
										:class="orderField== 'can_used_money'&& orderBy=='DESC'?'cprimary':''"><i
											class="el-icon-caret-bottom"></i></div>
								</div>
							</div>
						</template>
						<template slot-scope="scope">
							{{scope.row.can_used_money | tofixed}}
						</template>
					</el-table-column>
					<!-- <el-table-column prop="group_quota" sortable label="剩余积分"></el-table-column> -->
					<el-table-column label="监控状态" align="center">
						<template slot-scope="scope">
							<div v-if="!(scope.row.card == '总计' || scope.row.card == '当页总计')">
								<div :class="scope.row.is_online==1?'csuccess':'cdanger'">
									{{scope.row.is_online==1?'在线':scope.row.is_online==0?'离线':scope.row.is_online==2?'掉线':''}}
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column label="启停状态" align="center">
						<template slot-scope="scope">
							<div v-if="!(scope.row.card == '总计' || scope.row.card == '当页总计')">
								<div :class="scope.row.state==0?'csuccess':'cdanger'">{{scope.row.state==0?'正常':'停用'}}
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column label="锁定状态" align="center">
						<template slot-scope="scope">
							<div v-if="!(scope.row.card == '总计' || scope.row.card == '当页总计')">
								<!-- 2锁定  1正常 -->
								<div :class="scope.row.locked==2?'cdanger':'csuccess'">{{scope.row.locked==2?'锁定':'正常'}}
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column label="冻结状态" align="center">
						<template slot-scope="scope">
							<div v-if="!(scope.row.card == '总计' || scope.row.card == '当页总计')">
								<div :class="scope.row.is_freeze==0?'csuccess':'cdanger'">
									{{scope.row.is_freeze==0?'正常':'冻结'}}</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column label="连续失败次数" align="center">
						<template slot-scope="scope">
							<div v-if="!(scope.row.card == '总计' || scope.row.card == '当页总计')">
								<div :class="scope.row.fail_count>0?'cdanger':'csuccess'">{{scope.row.fail_count}}</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column label="成功率" align="center">
						<template slot-scope="scope">
							<div v-if="!(scope.row.card == '总计' || scope.row.card == '当页总计')">
								<div class="csuccess">{{scope.row.success_ratio | tofixed}}%</div>
							</div>
						</template>
					</el-table-column>
					<!-- <el-table-column label="查看" align="center">
						<template slot-scope="scope">
							<div v-if="!(scope.row.card == '总计' || scope.row.card == '当页总计')">
								<el-link type="primary">代付情况</el-link>
							</div>
						</template>
					</el-table-column> -->
					<!-- <el-table-column label="运营" align="center">
						<el-table-column prop="wx_sum_gold" label="微信进记录" sortable width='115px'>
							<template slot-scope="scope">
								{{scope.row.wx_sum_gold}}
							</template>
						</el-table-column>
						<el-table-column prop="income_sum" label="进" sortable>
							<template slot-scope="scope">
								{{scope.row.income_sum | tofixed}}
							</template>
						</el-table-column>
						<el-table-column prop="old_gold" label="出" sortable>
							<template slot-scope="scope">
								{{scope.row.old_gold}}
							</template>
						</el-table-column>
					</el-table-column> -->
					<el-table-column min-width='115px' align="center">
						<template slot="header" slot-scope="scope">
							<div class="flexX flexcenter">
								<span class="mr10">今日收</span>
								<div>
									<!-- orderField orderBy -->
									<div class="orderIcon" @click="orderData('money_in','ASC')"
										:class="orderField== 'money_in'&& orderBy=='ASC'?'cprimary':''"><i
											class="el-icon-caret-top"></i></div>
									<div class="orderIcon" @click="orderData('money_in','DESC')"
										:class="orderField== 'money_in'&& orderBy=='DESC'?'cprimary':''"><i
											class="el-icon-caret-bottom"></i></div>
								</div>
							</div>

						</template>
						<template slot-scope="scope">
							<span :class="(Number(scope.row.eve_used_money) == 0 && Number(limitreceive) < Number(scope.row.money_in))||(Number(scope.row.eve_used_money) > 0 && Number(scope.row.eve_used_money) < Number(scope.row.money_in))?'cdanger':''">{{scope.row.money_in | tofixed}}</span>
							<!-- <span :class=" Number(limitreceive) > Number(scope.row.money_in)?'cdanger':''">{{scope.row.money_in | tofixed}}</span> -->
						</template>
					</el-table-column>
					<el-table-column min-width='115px' align="center">
						<template slot="header" slot-scope="scope">
							<div class="flexX flexcenter">
								<span class="mr10">今日出</span>
								<div>
									<!-- orderField orderBy -->
									<div class="orderIcon" @click="orderData('money_out','ASC')"
										:class="orderField== 'money_out'&& orderBy=='ASC'?'cprimary':''"><i
											class="el-icon-caret-top"></i></div>
									<div class="orderIcon" @click="orderData('money_out','DESC')"
										:class="orderField== 'money_out'&& orderBy=='DESC'?'cprimary':''"><i
											class="el-icon-caret-bottom"></i></div>
								</div>
							</div>

						</template>
						<template slot-scope="scope">
							{{scope.row.money_out | tofixed}}
						</template>
					</el-table-column>
					<el-table-column label="业务" align="center">
						<el-table-column min-width='119px' align="center">
							<template slot="header" slot-scope="scope">
								<div class="flexX flexcenter">
									<span class="mr10">昨日业务余额</span>
									<div>
										<div class="orderIcon" @click="orderData('yes_sys_quota','ASC')"
											:class="orderField== 'yes_sys_quota'&& orderBy=='ASC'?'cprimary':''"><i
												class="el-icon-caret-top"></i></div>
										<div class="orderIcon" @click="orderData('yes_sys_quota','DESC')"
											:class="orderField== 'yes_sys_quota'&& orderBy=='DESC'?'cprimary':''"><i
												class="el-icon-caret-bottom"></i></div>
									</div>
								</div>
							</template>
							<template slot-scope="scope">
								{{scope.row.yes_sys_quota | tofixed}}
							</template>
						</el-table-column>
						<el-table-column min-width='119px' align="center">
							<template slot="header" slot-scope="scope">
								<div class="flexX flexcenter">
									<span class="mr10">今日业务余额</span>
									<div>
										<div class="orderIcon" @click="orderData('sys_quota','ASC')"
											:class="orderField== 'sys_quota'&& orderBy=='ASC'?'cprimary':''"><i
												class="el-icon-caret-top"></i></div>
										<div class="orderIcon" @click="orderData('sys_quota','DESC')"
											:class="orderField== 'sys_quota'&& orderBy=='DESC'?'cprimary':''"><i
												class="el-icon-caret-bottom"></i></div>
									</div>
								</div>
							</template>
							<template slot-scope="scope">
								{{scope.row.sys_quota | tofixed}}
							</template>
						</el-table-column>
					</el-table-column>
					<el-table-column label="现金" align="center">
						<el-table-column min-width='115px' align="center">
							<template slot="header" slot-scope="scope">
								<div class="flexX flexcenter">
									<span class="mr10">昨日卡余额</span>
									<div>
										<div class="orderIcon" @click="orderData('yes_true_quota','ASC')"
											:class="orderField== 'yes_true_quota'&& orderBy=='ASC'?'cprimary':''"><i
												class="el-icon-caret-top"></i></div>
										<div class="orderIcon" @click="orderData('yes_true_quota','DESC')"
											:class="orderField== 'yes_true_quota'&& orderBy=='DESC'?'cprimary':''"><i
												class="el-icon-caret-bottom"></i></div>
									</div>
								</div>
							</template>
							<template slot-scope="scope">
								{{scope.row.yes_true_quota | tofixed}}
							</template>
						</el-table-column>
						<el-table-column min-width='115px' align="center">
							<template slot="header" slot-scope="scope">
								<div class="flexX flexcenter">
									<span class="mr10">今日卡余额</span>
									<div>
										<div class="orderIcon" @click="orderData('true_quota','ASC')" :class="orderField== 'true_quota'&& orderBy=='ASC'?'cprimary':''">
											<i class="el-icon-caret-top"></i>
										</div>
										<div class="orderIcon" @click="orderData('true_quota','DESC')" :class="orderField== 'true_quota'&& orderBy=='DESC'?'cprimary':''">
											<i class="el-icon-caret-bottom"></i>
										</div>
									</div>
								</div>
							</template>
							<template slot-scope="scope">
								<span v-if="!(scope.row.card == '总计' || scope.row.card == '当页总计')" :class="Number(limitSave) < Number(scope.row.true_quota)|| Number(scope.row.keep_gold) < Number(scope.row.true_quota) ?'cdanger':''">{{scope.row.true_quota | tofixed}}</span>
								<span v-if="(scope.row.card == '总计' || scope.row.card == '当页总计')">{{scope.row.true_quota | tofixed}}</span>
								<!-- {{scope.row.true_quota | tofixed}} -->
							</template>
						</el-table-column>
						<!-- 累计收款上限 留存上限 今日收 今日出 -->

					</el-table-column>
					<el-table-column min-width='119px' align="center">
						<template slot="header" slot-scope="scope">
							<div class="flexX flexcenter">
								<span class="mr10">累计收款上限</span>
								<div>
									<div class="orderIcon" @click="orderData('eve_used_money','ASC')"
										:class="orderField== 'eve_used_money'&& orderBy=='ASC'?'cprimary':''"><i
											class="el-icon-caret-top"></i></div>
									<div class="orderIcon" @click="orderData('eve_used_money','DESC')"
										:class="orderField== 'eve_used_money'&& orderBy=='DESC'?'cprimary':''"><i
											class="el-icon-caret-bottom"></i></div>
								</div>
							</div>
						</template>
						<template slot-scope="scope">
							{{scope.row.eve_used_money | tofixed}}
						</template>
					</el-table-column>
					<el-table-column min-width='115px' align="center">
						<template slot="header" slot-scope="scope">
							<div class="flexX flexcenter">
								<span class="mr10">留存上限</span>
								<div>
									<div class="orderIcon" @click="orderData('keep_gold','ASC')"
										:class="orderField== 'keep_gold'&& orderBy=='ASC'?'cprimary':''"><i
											class="el-icon-caret-top"></i></div>
									<div class="orderIcon" @click="orderData('keep_gold','DESC')"
										:class="orderField== 'keep_gold'&& orderBy=='DESC'?'cprimary':''"><i
											class="el-icon-caret-bottom"></i></div>
								</div>
							</div>
						</template>
						<template slot-scope="scope">
							{{scope.row.keep_gold | tofixed}}
						</template>
					</el-table-column>

					<el-table-column min-width='70px' align="center">
						<template slot="header" slot-scope="scope">
							<div class="flexX flexcenter">
								<span class="mr10">差值</span>
								<div>
									<div class="orderIcon" @click="orderData('diff_money','ASC')"
										:class="orderField== 'diff_money'&& orderBy=='ASC'?'cprimary':''"><i
											class="el-icon-caret-top"></i></div>
									<div class="orderIcon" @click="orderData('diff_money','DESC')"
										:class="orderField== 'diff_money'&& orderBy=='DESC'?'cprimary':''"><i
											class="el-icon-caret-bottom"></i></div>
								</div>
							</div>
						</template>
						<template slot-scope="scope">
							<div v-if="scope.row.card">
								{{scope.row.diff_money | tofixed}}
							</div>
						</template>
					</el-table-column>
				</el-table>
			</template>
		</div>
		<div class="flexX mt20 pageBox">
			<el-pagination :hide-on-single-page="true" @size-change="handleSizeChange"
				@current-change="handleCurrentChange" :current-page="page" :page-sizes="pageSizesArr"
				:page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
		</div>


		<el-dialog center class="dialog" top="100px" title="" :visible.sync="dialogVisible" @close="close" width="70%"
			:close-on-click-modal="false">
			<div class="formBox" v-loading="dialogloading">
				<div>
					<el-tabs type="border-card" @tab-click="getInfoData" v-model="activeName">
						<el-tab-pane label="搬砖工" name='info'>
							<div class="flexX flexAround flexTop">

								<div>
									<el-form label-position="right" label-width="80px">
										<el-form-item label="用户名:">{{infoTotalData.name}}</el-form-item>
										<el-form-item label="账号:">{{infoTotalData.username}}</el-form-item>
										<el-form-item label="Skype:">
											<!-- {{infoTotalData.skype}} -->
											<el-link type="primary" :href="`skype:${infoTotalData.skype}?chat`"
												οnclick="return skypeCheck();">{{infoTotalData.skype}}</el-link>
										</el-form-item>
										<el-form-item label="手机号:">{{infoTotalData.telephone}}</el-form-item>
										<el-form-item label="上级:">{{infoTotalData.all_parent_name}}</el-form-item>
										<el-form-item label="银行:">{{infoTotalData.bank}}</el-form-item>
										<el-form-item label="卡号:">{{infoTotalData.card}}</el-form-item>
										<el-form-item label="姓名:">{{infoTotalData.true_name}}</el-form-item>
									</el-form>
								</div>
								<div>
									<el-form label-position="right" label-width="80px">
										<el-form-item label="提现金额:">{{infoTotalData.tx_money | tofixed}}</el-form-item>
										<!-- <el-form-item label="提成:">{{infoTotalData.total_tc | tofixed}}</el-form-item> -->
										<el-form-item label="今日奖金:">{{infoTotalData.today_yj | tofixed}}</el-form-item>
										<el-form-item label="今日提成:">{{infoTotalData.today_tc | tofixed}}</el-form-item>
										<el-form-item label="卡数量:">{{infoTotalData.card_count}}</el-form-item>
										<el-form-item label="充值状态:"> <span
												:class="infoTotalData.pay_state==0?'csuccess':'cdanger'">{{infoTotalData.pay_state==0?'启用':'禁用'}}</span>
										</el-form-item>
										<el-form-item label="代付状态:"> <span
												:class="infoTotalData.repay_state==0?'csuccess':'cdanger'">{{infoTotalData.repay_state==0?'启用':'禁用'}}</span>
										</el-form-item>
										<el-form-item label="通道:">{{infoTotalData.business_name}}</el-form-item>
									</el-form>
								</div>
							</div>
						</el-tab-pane>
						<el-tab-pane label="卡列表" name='bankList'>
							<!-- <div class="mb20"><span class="mr20">用户名：{{infoTotalData.name}}</span><span>账号：{{infoTotalData.username}}</span></div> -->
							<div class="mb20">
								<span class="mr20 mb20">用户名：{{infoTotalData.name}}</span>
								<span class="mr20 mb20">账号：{{infoTotalData.username}}</span>
								<span
									class="mr20 mb20">累计收款上限：{{cardListData.length>0?cardListData[cardListData.length-1].eve_used_money:'0.00'}}</span>
								<span
									class="mr20 mb20">留存上限：{{cardListData.length>0?cardListData[cardListData.length-1].keep_gold:'0.00'}}</span>
								<span
									class="mr20 mb20">真实余额：{{cardListData.length>0?cardListData[cardListData.length-1].true_quota:'0.00'}}</span>
							</div>
							<div class="mb20">
								<el-button size="mini" plain class="mr20" @click="cardListsetDate(-1)"
									:disabled="cardListdisLoading">前一天</el-button>
								<el-date-picker size="mini" value-format="yyyy-MM-dd" v-model="cardListdate"
									type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
									class="mr20"></el-date-picker>
								<el-button size="mini" plain class="mr20" @click="cardListsetDate(1)"
									:disabled="cardListdisLoading">后一天</el-button>
								<el-button size="mini" type="primary"
									:icon="cardListdisLoading?'el-icon-loading':'el-icon-search'"
									:disabled="cardListdisLoading" @click="cardListgetData">搜索</el-button>
							</div>
							<el-table size="mini" :data="cardListData" border="" stripe max-height="500px">
								<el-table-column prop="card" label="卡号" width="145px" align="center">
									<template slot-scope='scope'>
										<div v-if="scope.$index < cardListData.length-2">{{scope.row.card}}</div>
										<div v-else="">{{scope.row.all_parent_name}}</div>
									</template>
								</el-table-column>
								<el-table-column prop="bank" label="银行" align="center"></el-table-column>
								<el-table-column prop="true_name" label="姓名" align="center"></el-table-column>
								<el-table-column prop="telephone" label="电话" align="center"></el-table-column>
								<el-table-column label="昨日结余" align="center">
									<template slot-scope='scope'>
										<div>{{scope.row.yes_sys_quota | tofixed}}</div>
									</template>
								</el-table-column>
								<el-table-column label="今日收款" align="center">
									<template slot-scope='scope'>
										<div>{{scope.row.money_in | tofixed}}</div>
									</template>
								</el-table-column>
								<el-table-column label="今日出款" align="center">
									<template slot-scope='scope'>
										<div>{{scope.row.money_out | tofixed}}</div>
									</template>
								</el-table-column>
								<el-table-column label="当前余额" align="center">
									<template slot-scope='scope'>
										<div>{{scope.row.sys_quota | tofixed}}</div>
									</template>
								</el-table-column>
								<el-table-column label="累计收款上限" align="center">
									<template slot-scope='scope'>
										<div>{{scope.row.eve_used_money | tofixed}}</div>
									</template>
								</el-table-column>
								<el-table-column label="留存上限" align="center">
									<template slot-scope='scope'>
										<div>{{scope.row.keep_gold | tofixed}}</div>
									</template>
								</el-table-column>
								<el-table-column label="真实余额" align="center">
									<template slot-scope='scope'>
										<div>{{scope.row.true_quota | tofixed}}</div>
									</template>
								</el-table-column>
							</el-table>
							<div class="flexX  mt20">
								<el-pagination :hide-on-single-page="true" @size-change="diahandleSizeChange"
									@current-change="diahandleCurrentChange" :current-page="diapage"
									:page-sizes="diapageSizesArr" :page-size="diapageSize"
									layout="total, sizes, prev, pager, next, jumper" :total="diatotal"></el-pagination>
							</div>
						</el-tab-pane>

						<el-tab-pane label="卡进账订单" name='cardNote'>
							<div class="mb20">
								<span class="mr20">用户名：{{infoTotalData.name}}</span><span class="mr20">账号：{{infoTotalData.username}}</span>
								<span>时间：</span>
								<el-date-picker size="mini" v-model="dateIn" type="datetimerange" range-separator="至" start-placeholder="开始日期"
								 end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']" class="mr20"></el-date-picker>
								 <el-button size="mini" type="primary" :icon="disLoadingIn?'el-icon-loading':'el-icon-search'" @click="getDataIn('in')" :disabled="disLoadingIn">搜索</el-button>
							</div>
							<el-table size="mini" :data="cardNoteData" border="" stripe max-height="500">
								<el-table-column prop="true_name" label="姓名" align="center">
									<template slot-scope='scope'>
										<div v-if="scope.row.id">{{scope.row.true_name}}</div>
										<div v-if="!scope.row.id">{{scope.row.name}}</div>
									</template>
								</el-table-column>
								<el-table-column prop="card" label="卡号" align="center"></el-table-column>
								<el-table-column prop="order_id" label="订单号" align="center"></el-table-column>
								<el-table-column label="金额" align="center">
									<template slot-scope='scope'>
										<div>{{scope.row.gold | tofixed}}</div>
									</template>
								</el-table-column>
								<el-table-column prop="action_time" label="时间" align="center"></el-table-column>

							</el-table>
							<div class="flexX  mt20">
								<el-pagination :hide-on-single-page="true" @size-change="nodehandleSizeChange"
									@current-change="nodehandleCurrentChange" :current-page="nodepage"
									:page-sizes="nodepageSizesArr" :page-size="nodepageSize"
									layout="total, sizes, prev, pager, next, jumper" :total="nodetotal"></el-pagination>
							</div>
						</el-tab-pane>

						<el-tab-pane label="卡出账订单" name='cardNoteOut'>
							<span>时间：</span>
							<el-date-picker size="mini" v-model="dateOut" type="datetimerange" range-separator="至" start-placeholder="开始日期"
							 end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']" class="mr20"></el-date-picker>
							 <el-button size="mini" type="primary" :icon="disLoadingOut?'el-icon-loading':'el-icon-search'" @click="getDataIn('out')" :disabled="disLoadingOut">搜索</el-button>
							<el-table size="mini" :data="cardNoteOutData" border="" stripe max-height="500">
								<el-table-column prop="true_name" label="姓名" align="center">
									<template slot-scope='scope'>
										<div v-if="scope.row.id">{{scope.row.true_name}}</div>
										<div v-if="!scope.row.id">{{scope.row.name}}</div>
									</template>
								</el-table-column>
								<el-table-column prop="card" label="卡号" align="center"></el-table-column>
								<el-table-column prop="order_id" label="订单号" align="center"></el-table-column>
								<el-table-column label="金额" align="center">
									<template slot-scope='scope'>
										<div>{{scope.row.gold | tofixed}}</div>
									</template>
								</el-table-column>
								<el-table-column prop="action_time" label="时间" align="center"></el-table-column>
							</el-table>
							<div class="flexX  mt20">
								<el-pagination :hide-on-single-page="true" @size-change="outhandleSizeChange"
									@current-change="outhandleCurrentChange" :current-page="outpage"
									:page-sizes="outpageSizesArr" :page-size="outpageSize"
									layout="total, sizes, prev, pager, next, jumper" :total="outtotal"></el-pagination>
							</div>
						</el-tab-pane>

						<!-- 卡账单 -->
						<el-tab-pane label="卡账单" name='cardBill'>
							<div class="mb20">
								<span class="mr20">用户名：{{infoTotalData.name}}</span>
								<span class="mr20">账号：{{infoTotalData.username}}</span>
								<span class="mr20" v-if="cardBillData.length>0">姓名：{{cardBillData[0].name}}</span>
								<span class="mr20" v-if="cardBillData.length>0">卡号：{{cardBillData[0].card}}</span>
								<span>更新时间：{{bill_update_time}}</span>
							</div>
							<el-table size="mini" :data="cardBillData" border="" stripe max-height="500px">
								<el-table-column type="index" label="序号" align="center" width="50px"></el-table-column>
								<el-table-column prop="water_no" label="系统订单号" align="center" min-width="140px"></el-table-column>
								<el-table-column label="交易状态" align="center" min-width="140px">
									<template slot-scope='scope'>
										<div v-if="scope.row.state_name">{{scope.row.state_name}}</div>
										<div v-if="scope.row.type == 1 && (scope.row.state == 7 || scope.row.water_no == '')">
											<el-link type="primary"@click="billshowDia(scope.row,scope.$index,'billadd')">补单</el-link>
										</div>
										<div v-if="scope.row.type == 0 && scope.row.water_no == '' && scope.row.repay_is_loss == 0">
											<el-link type="danger"@click="billshowDia(scope.row,scope.$index,'billloss')">报损</el-link>
										</div>
										<div v-if="scope.row.type == 0 && scope.row.water_no == '' && scope.row.repay_is_loss == 1">
											已报损
										</div>
									</template>
								</el-table-column>
								<el-table-column prop="trans_date" label="交易时间" align="center" min-width="140px">
								</el-table-column>
								<el-table-column label="入款记录" align="center">
									<template slot-scope='scope'>
										<div v-if="scope.row.type == 1">{{scope.row.amount | tofixed}}</div>
										<!-- <div v-if="scope.row.type == 0">0</div> -->
									</template>
								</el-table-column>
								<el-table-column label="出款记录" align="center">
									<template slot-scope='scope'>
										<div v-if="scope.row.type == 0">{{scope.row.amount | tofixed}}</div>
										<!-- <div v-if="scope.row.type == 1">0</div> -->
									</template>
								</el-table-column>
								<el-table-column prop="transplace" label="收支摘要" align="center" min-width="140px">
									<template slot-scope='scope'>
										<div :class="scope.row.type=='0'?'cdanger':'csuccess'">{{scope.row.transplace}}</div>
									</template>
								</el-table-column>
								<el-table-column label="账户余额" align="center">
									<template slot-scope='scope'>
										<div>{{scope.row.keep_amount | tofixed}}</div>
									</template>
								</el-table-column>
								<el-table-column prop="pay_card" label="对方账户" align="center" min-width="140px">
								</el-table-column>
								<el-table-column prop="pay_name" label="对方户名" align="center" width="140px">
								</el-table-column>
								<el-table-column prop="remark" label="备注" align="center" width="140px">
								</el-table-column>

							</el-table>
							<div class="flexX  mt20">
								<el-pagination :hide-on-single-page="true" @size-change="billhandleSizeChange"
									@current-change="billhandleCurrentChange" :current-page="billpage"
									:page-sizes="billpageSizesArr" :page-size="billpageSize"
									layout="total, sizes, prev, pager, next, jumper" :total="billtotal"></el-pagination>
							</div>
						</el-tab-pane>


					</el-tabs>
				</div>
				<div class="textCenter">
					<el-button type="primary" @click="close">关闭</el-button>
				</div>
			</div>
		</el-dialog>

		<el-dialog center class="dialog" top="150px" title="" :visible.sync="payVisible" @close="close"
			:close-on-click-modal="false">
			<div class="formBox" v-loading="dialogloading">

			</div>
		</el-dialog>
		<el-dialog width="800px" center top="150px" :title="billTitle" :visible.sync="billbddialogVisible" @close="billbdclose"
			 :close-on-click-modal="false">
				<div class="formBoxbd" v-loading="billbddialogloading">
					<div v-if="billType == 'billadd'">
						<el-form label-position="right" label-width="100px">
							<el-form-item label="金额">
								{{billbditem.amount | tofixed}}元
							</el-form-item>
							<el-form-item label="订单编号">
								<!-- {{bditem.order_id}} -->
								<el-select v-model="failOrder" filterable placeholder="请选择订单编号" size="mini" class="mr20" clearable>
									<el-option v-for="(item) in failOrderList" :key="item.id" :label="item.trader_order_id+item.order_id" :value="item.id">
										 <span>{{item.trader_order_id}} | {{item.order_id}} | {{item.business_name}} | {{item.add_gold | tofixed}} | {{item.add_time}}</span>
									</el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="补单类型">
								<el-select v-model="replenState" placeholder="请选择补单类型" size="mini" class="mr20" clearable>
									<el-option v-for="(item,index) in replenishmentStateObj" :key="index" :label="item" :value="index"></el-option>
								</el-select>
								
							</el-form-item>
						</el-form>
					</div>
					<div v-if="billType == 'billloss'">
						<el-form label-position="right" label-width="100px">
							
							<el-form-item label="姓名">
								{{item.true_name}}
							</el-form-item>
							<el-form-item label="银行">
								{{item.bank}}
							</el-form-item>
							<el-form-item label="卡号">
								{{item.card}}
							</el-form-item>
							<el-form-item label="搬砖工">
								{{infoTotalData.name}}
							</el-form-item>
							<el-form-item label="差值">
								<span>{{item.true_quota - item.sys_quota  | tofixed}}</span>
							</el-form-item>
							<el-form-item label="金额">
								{{billbditem.amount | tofixed}}元
							</el-form-item>
							
							<el-form-item label="报损类型">
								<el-select v-model="losseType" filterable placeholder="请选择报损类型" size="mini" class="mr20" clearable>
									<el-option v-for="(item) in losseTypeList" :key="item.id" :label="item.label" :value="item.id"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="备注">
								<el-input size="mini" v-model="losseNote" placeholder="请输入备注" style="width: 200px;"></el-input>
							</el-form-item>
						</el-form>
					</div>
					<div class="textCenter">
						<el-button type="primary" @click="billonSubmit">确定</el-button>
					</div>
				</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		isEmpty,
		isPositiveNumber,
		isMoney
	} from '@/util/validate'
	import {
		Message
	} from 'element-ui'
	import local from '../../util/local.js'
	export default {
		data() {
			return {
				height: 0,
				loading: false,
				disLoading: false,
				tableData: [],
				traderArr: [],
				//启停状态
				stateArr: [{
						id: 0,
						label: '正常'
					},
					{
						id: 1,
						label: '停用'
					}
				],
				freezeArr: [{
					id: 1,
					label: '冻结'
				}, {
					id: 0,
					label: '正常'
				}],
				state: 0, //启停状态
				freeze: 0, //冻结状态
				trader: '',
				bankListArr: [],
				countAll: {
					money_in: "0.00",
					money_out: "0.00",
					money: "0.00",
					money_uncertain: "0.00",
					alipay_eve_used_money: "0.00",
					card_keep_gold: "0.00",
				},
				limitreceive: 0, //当前今日收款限额
				receiveStep: 5000, //今日收款限额调整 步子
				limitcard: 0, //当前配卡数
				cardStep: 1, //当前配卡数 步子
				limitSave: 0, //可存留上线
				saveStep: 5000, //可存留上线 步子
				limitRoundrobin: 0, //轮巡上限
				roundrobinStep: 5000, //轮巡上限 步子
				pageSizesArr: [20, 50, 100, 200, 5000],
				pageSize: 20,
				total: 0,
				page: 1,

				diapageSizesArr: [20, 50, 100, 200],
				diapageSize: 20,
				diatotal: 0,
				diapage: 1,
				nodepageSizesArr: [20, 50, 100, 200],
				nodepageSize: 20,
				nodetotal: 0,
				nodepage: 1,
				outpageSizesArr: [20, 50, 100, 200],
				outpageSize: 20,
				outtotal: 0,
				outpage: 1,
				billpageSizesArr: [20, 50, 100, 200],
				billpageSize: 20,
				billtotal: 0,
				billpage: 1,
				dialogVisible: false,
				dialogloading: false,
				item: {},
				infoTotalData: {}, //搬砖工
				cardListData: [], //银行卡列表
				cardNoteData: [], //银行卡记录  流水
				cardNoteOutData: [], //卡出账记录 
				cardBillData: [], //卡账单
				bill_update_time: '', //卡账单 最后更新时间
				activeName: 'info',
				limitloading: false,
				payVisible: false,
				cardListdate: null,
				cardListdisLoading: false,
				//排序
				orderField: '', //排序字段
				orderBy: '', //排序类型
				//
				is_online: 1,
				is_onlineArr: [{
					id: 1,
					label: '在线'
				}, {
					id: 0,
					label: '离线'
				}, {
					id: 2,
					label: '掉线'
				}],
				locked: 1,
				lockedArr: [{
					id: 2,
					label: '锁定',
				}, {
					id: 1,
					label: '正常',
				}],
				chooseTraderArr: [],
				showTraderArr:[],
				//卡账单补单
				replenState: '',
				replenishmentStateObj: {}, //补单类型
				billbddialogVisible: false, //补单弹框
				billbditem: {}, //当前补单的数据
				// index: {}, //当前item 索引
				// orderType:"",//订单操作类型  cancle 撤单
				billbddialogloading: false,
				failOrderList:[],
				failOrder:'',
				//报损
				lossmoney:'',
				losseType:'',
				losseTypeList:[{id:'1',label:'从搬砖工扣除'},{id:'2',label:'从平台扣除'}],
				losseNote:'',
				//卡账单报损
				billType:'',//billadd 卡账单补单   billloss 卡账单报损
				billTitle:'',//账单弹出框title
				bank: '',
				dateIn:null,
				dateOut:null,
				disLoadingIn:false,
				disLoadingOut:false
			}
		},
		created() {
			this.cardListdate = [this.$util.timestampToTime(new Date().getTime(), true), this.$util.timestampToTime(
				new Date().getTime(), true)]
			
			this.bankList()
			this.traderList()
			this.$nextTick(() => {				
				let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
				let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
				let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
				let documentHeight = document.documentElement.clientHeight
				this.height = documentHeight - topNav - 20 - pageBox - 20 - queryBox
				
			})
		},
		filters: {
			tofixed: function(value) {
				if (value || value == 0) {
					// return ((value * 1).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
					let val =Math.floor(((value * 1)*100).toPrecision(12))
					return ((val/100).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
				}
			}
		},
		methods: {
			cardListsetDate(day) {
				let data = this.cardListdate ? this.cardListdate[0] : this.$util.timestampToTime(new Date().getTime(),
					true)
				let newDate = this.$util.getLastDate(data, day);
				this.cardListdate = [newDate, newDate]
				this.cardListgetData()
				// console.log(this.date)
			},
			cardListgetData() {
				this.diapage = 1;
				this.cardList()
			},
			traderList() {
				this.$api.traderList({}).then(res => {
					if (res.status == 1) {
						this.traderArr = res.data.data
						let chooseTraderArr = this.$local.get('chooseTraderArr');
						if(chooseTraderArr){
							this.chooseTraderArr = chooseTraderArr;
						}
						else{
							this.chooseTraderArr = []
						}
						this.getShowTrader()
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}

				})
			},
			bankList() {
				this.$api.bankList({}).then(res => {
					if (res.status == 1) {
						this.bankListArr = res.data;
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			limit(name, type, step) {
				if (isEmpty(this.trader, "请选择商家")) {
					return
				}
				if (type == 'cut') {
					if (this[name] < step) {
						return
					}
					this[name] = this[name] - step
				} else {
					this[name] = this[name] + step
				}
				this.setTraderConfig(name)
			},
			traderCardList() {
				let data = {
					business_no: this.trader,
					freeze: this.freeze,
					state: this.state,
					locked: this.locked,
					is_online: this.is_online,
					orderField: this.orderField,
					orderBy: this.orderBy,
					page: this.page,
					size: this.pageSize,
					// bank: this.bank,
				}
				if (!this.trader) {
					this.$message({
						type: 'error',
						message: '请选择商家'
					});
					return
				}
				// this.loading = true
				this.disLoading = true
				this.$api.alipayList(data).then(res => {
					this.loading = false
					this.disLoading = false
					if (res.status == 1) {
						this.tableData = res.data.data
						this.total = res.data.total
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						});
					}
				}).catch(error => {
					this.loading = false
					this.disLoading = false
				})
			},
			traderCardcountAll() {
				let data = {
					business_no: this.trader
				}
				if (!this.trader) {
					this.$message({
						type: 'error',
						message: '请选择商家'
					});
					return
				}
				this.$api.traderAlipaycountAll(data).then(res => {
					if (res.status == 1) {
						this.countAll = res.data
						this.limitreceive = Math.trunc(this.countAll.eve_used_money)
						this.limitSave = Math.trunc(this.countAll.keep_gold)
						this.limitcard = Math.trunc(this.countAll.used_card_count)
						this.limitRoundrobin = Math.trunc(this.countAll.polling_money)
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						});
					}
				}).catch(error => {})

			},
			queryData() {
				this.page = 1
				this.traderCardList();
				this.traderCardcountAll();
			},
			queryDataAll(){
				this.state='';
				this.freeze='';
				this.is_online='';
				this.locked='';
				this.bank='';
				this.page = 1
				this.traderCardList();
				this.traderCardcountAll();
			},
			setTraderConfig(name) {
				if (isEmpty(this.trader, "请选择商家")) {
					return
				}
				let data = {
					business_no: this.trader,
				}
				if (name == 'limitreceive') {
					data.eve_used_money = this.limitreceive
				}
				if (name == 'limitSave') {
					data.keep_gold = this.limitSave
				}
				if (name == 'limitcard') {
					data.used_card_count = this.limitcard
				}
				if (name == 'limitRoundrobin') {
					data.polling_money = this.limitRoundrobin
				}
				this.limitloading = true;
				this.$api.setTraderConfig(data).then(res => {
					this.limitloading = false;
					if (res.status == 1) {
						this.$message({
							type: "success",
							message: "设置成功"
						})
					} else {
						this.$message({
							type: "error",
							message: res.msg
						})
					}
				}).catch(error => {
					this.limitloading = false;
				})
			},
			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
				this.pageSize = val
				this.page = 1
				this.traderCardList()
			},
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.page = val
				this.traderCardList()
			},
			diahandleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.diapageSize = val
				this.diapage = 1
				this.cardList()
			},
			diahandleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.diapage = val
				this.cardList()
			},

			nodehandleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.nodepage = 1
				this.nodepageSize = val
				this.moneyList()
			},
			nodehandleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.nodepage = val
				this.moneyList()
			},
			outhandleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.outpageSize = val
				this.outpage = 1
				this.outList()
			},
			outhandleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.outpage = val
				this.outList()
			},
			billhandleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.billpage = 1
				this.billpageSize = val
				this.cardBill()
			},
			billhandleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.billpage = val
				this.cardBill()
			},
			showInfo(item) {
				// this.$router.push({path:'/orderInfo'})
				this.dialogVisible = true;
				this.item = item
				this.infoTotal(item)
			},
			getInfoData(val) {
				if (val.name == 'info') {
					if (JSON.stringify(this.infoTotalData) == '{}') {
						this.infoTotal()
					}
				}
				if (val.name == 'bankList') {
					if (this.cardListData.length == 0) {
						this.cardList()
					}
				}
				if (val.name == 'cardNote') {
					if (this.cardNoteData.length == 0) {
						this.moneyList()
					}
				}
				if (val.name == 'cardNoteOut') {
					if (this.cardNoteOutData.length == 0) {
						this.outList()
					}
				}
				if (val.name == 'cardBill') {
					if (this.cardBillData.length == 0) {
						this.cardBill()
					}
				}
				console.log('val', val)
			},
			infoTotal(item) {
				let data = {
					id: item.bank_card_id,
					type: 2
				}
				// this.dialogloading = true
				this.$api.infoTotal(data).then(res => {
					this.dialogloading = false
					if (res.status == 1) {
						this.infoTotalData = res.data
					} else {
						this.$message({
							"type": "error",
							message: res.msg
						})
					}
				}).catch(error => {
					this.dialogloading = false
				})
			},
			cardList(item) {
				let data = {
					carder_id: this.infoTotalData.id,
					size: this.diapageSize,
					page: this.diapage,
					startdate: this.cardListdate ? this.cardListdate[0] : '',
					enddate: this.cardListdate ? this.cardListdate[1] : '',
					// type:2
				}

				// this.dialogloading = true

				this.cardListdisLoading = true
				this.$api.cardList(data).then(res => {

					this.cardListdisLoading = false
					this.dialogloading = false
					if (res.status == 1) {
						this.cardListData = res.data.data
						this.diatotal = res.data.total

					} else {
						this.$message({
							"type": "error",
							message: res.msg
						})
					}
				}).catch(error => {
					this.cardListdisLoading = false
					this.dialogloading = false
				})
			},
			close() {
				this.dialogVisible = false
				this.item = {}
				this.infoTotalData = {} //搬砖工
				this.cardListData = [] //银行卡列表
				this.cardNoteData = [] //银行卡记录  流水
				this.cardNoteOutData = []
				this.cardBillData = []
				this.activeName = 'info'
				this.diapage = 1
				this.nodepage = 1
				this.outpage = 1
				this.billpage = 1
				this.cardListdate = [this.$util.timestampToTime(new Date().getTime(), true), this.$util.timestampToTime(
					new Date().getTime(), true)]
				this.dateIn=null
				this.dateOut=null
				this.disLoadingIn=false
				this.disLoadingOut=false
			},
			moneyList() {
				let data = {
					page: this.nodepage,
					size: this.nodepageSize,
					id: this.item.bank_card_id,
					startdate:this.dateIn?this.$util.timestampToTime(Date.parse(new Date(this.dateIn[0]))):"",
					endate:this.dateIn?this.$util.timestampToTime(Date.parse(new Date(this.dateIn[1]))):""
				}
				this.disLoadingIn = true
				// this.dialogloading = true
				this.$api.moneyList(data).then(res => {
					this.dialogloading = false
					this.disLoadingIn = false
					if (res.status == 1) {
						this.cardNoteData = res.data.data
						this.nodetotal = res.data.total
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.disLoadingIn = false
					this.dialogloading = false
				})
			},
			outList() {
				let data = {
					page: this.outpage,
					size: this.outpageSize,
					id: this.item.bank_card_id,
					startdate:this.dateOut?this.$util.timestampToTime(Date.parse(new Date(this.dateOut[0]))):"",
					endate:this.dateOut?this.$util.timestampToTime(Date.parse(new Date(this.dateOut[1]))):""
				}
				this.disLoadingOut = true
				// this.dialogloading = true
				this.$api.outList(data).then(res => {
					this.dialogloading = false
					this.disLoadingOut = false
					if (res.status == 1) {
						this.cardNoteOutData = res.data.data
						this.outtotal = res.data.total
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.dialogloading = false
					this.disLoadingOut = false
				})
			},
			//卡账单
			cardBill() {
				let data = {
					page: this.billpage,
					size: this.billpageSize,
					id: this.item.bank_card_id,
				}
				// this.dialogloading = true
				this.$api.cardBill(data).then(res => {
					this.dialogloading = false
					if (res.status == 1) {
						this.cardBillData = res.data.data
						this.billtotal = res.data.total
						this.bill_update_time = res.data.bill_update_time
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.dialogloading = false
				})
			},
			//
			orderData(orderField, orderBy) {
				if (this.tableData.length == 0) {
					this.orderField = ""
					this.orderBy = ""
				} else {
					if (this.orderField == orderField && this.orderBy == orderBy) {
						this.orderField = ""
						this.orderBy = ""
					} else {
						this.orderField = orderField
						this.orderBy = orderBy
					}
					this.traderCardList()
				}

			},
			chooseTrader(val) {
				this.$local.set('chooseTraderArr',this.chooseTraderArr);
				this.getShowTrader();
			},
			getShowTrader(){
				let chooseTraderArr = this.$local.get('chooseTraderArr');
				if(!chooseTraderArr){
					chooseTraderArr = [];
				}
				this.showTraderArr = []
				chooseTraderArr.forEach(item=>{
					this.traderArr.forEach(allItem=>{
						if(item == allItem.business_no){
							this.showTraderArr.push(allItem)
						}
					})
				})
			},
			//卡账单补单
			billshowDia(item, index,type) {
				this.billbditem = item
				this.billType = type
				this.billbddialogVisible = true
				if(this.billType == "billadd"){
					//补单
					this.billTitle = "补单"
					if (JSON.stringify(this.replenishmentStateObj) == '{}') {
						this.replenishmentState()
					}
					this.getFailOrder()
				}else if(this.billType == "billloss"){
					this.billTitle = "银行卡报损"
				}
			},
			//补单类型
			replenishmentState() {
				this.$api.replenishmentState({}).then(res => {
					if (res.status == 1) {
						this.replenishmentStateObj = res.data
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			//根据卡查找失败的订单
			getFailOrder(){
				let data = {
					card:this.billbditem.card
				}
				this.$api.getFailOrder(data).then(res=>{
					this.failOrderList=res.data
				})
			},
			//确认补单
			billonSubmit() {
				
				if(this.billType == "billadd"){
					if (isEmpty(this.failOrder, '请选择订单')) {
						return
					}
					if (isEmpty(this.replenState, '请选择补单类型')) {
						return
					}
					let amount = ''
					for(let i = 0;i<this.failOrderList.length;i++){
						if(this.failOrderList[i].id == this.failOrder){
							amount = this.failOrderList[i].add_gold
							break;
						}
					}
					let data = {
						id: this.failOrder,
						state: this.replenState,
						amount: amount,
						bill_id:this.billbditem.id
					}
					// console.log(data)
					this.billbddialogloading = true;
					this.$api.replenishment(data).then(res => {
						this.billbddialogloading = false;
						if (res.status == 1) {
							this.cardBill()
							this.billbdclose();
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(() => {
						this.billbddialogloading = false;
					})
				}else if(this.billType == "billloss"){
					this.lossmoney = parseFloat(this.billbditem.amount).toFixed(2)
					console.log(this.lossmoney)
					this.lossSubmit('bill',this.billbditem.id)
				}
			},
			//关闭补单弹框
			billbdclose() {
				this.billbditem = {}
				this.billbddialogVisible = false
				this.replenState = ''
				this.failOrder = ''
				this.lossmoney=''
				this.losseType=''
				this.losseNote=''
				this.failOrderList = []
			},
			//报损
			lossSubmit(bill,id){
				if (!isMoney(this.lossmoney, '报损金额只能是数字，最多输入两位小数')) {
					return
				}
				if (isEmpty(this.losseType, '请选择报损类型')) {
					return
				}
				let data = {
					type:this.losseType,
					bank_card_id:this.item.bank_card_id,
					money:this.lossmoney,
					note:this.losseNote
				}
				if(bill == "bill"){
					data.bill_id = id
				}
				this.lossdialogloading = true
				this.$api.addBankCardLoss(data).then(res=>{
					this.lossdialogloading = false
					if(res.status == 1){
						this.traderCardList()
						if(bill == "bill"){
							this.billbdclose()
							this.cardBill()
						}else{							
							this.lossclose()
						}
						this.$message({type:"success",message:res.msg})
					}else{
						this.$message({type:"error",message:res.msg})
					}
				}).catch(error=>{
					this.lossdialogloading = false
				})
				
			},
			getDataIn(type){
				if(type == 'in'){
					this.moneyList()					
				}
				else if(type == 'out'){
					this.outList()
				}
			},
		},
		watch: {
			total(val, old) {
				this.$nextTick(() => {
					let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
					let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
					let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
					let documentHeight = document.documentElement.clientHeight
					this.height = documentHeight - topNav - 20 - pageBox - 20 - queryBox
				})
			}
		},
	}
</script>

<style scoped="scoped" lang="scss">
	@import '@/style/variables.scss';

	.countTotal {
		background-color: $tableBorderColor;
		padding: 10px;
		// height: 40px;
	}

	.operation {
		background-color: $tratercompositetopBlockBack;
		border: 1px solid $tratercompositetopBlockBorderBack;
		margin-bottom: 5px;
		padding: 20px 10px;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		// height: 142px;
		.cardSet {
			margin-right: 20px;
			display: flex;
			flex-direction: column;
			justify-content: center;

			.group {
				display: flex;
				align-items: center;

				.item {
					display: flex;
					align-items: center;
					margin-bottom: 5px;

					.label {
						display: flex;
						flex-direction: column;
						font-size: 15px;
						text-align: center;
						margin-right: 10px;
						width: 120px;
					}

					.input-number {
						padding: 5px 10px;
						background-color: $tratercompositetopBlockInputBack;
						// border: 1px solid #514f4f;
						display: flex;
						align-items: center;
						color: #fff;
						font-size: 18px;
						width: 140px;

						.num {
							margin: 0 10px;
							font-size: 16px;
							display: flex;
							flex-direction: column;
							text-align: center;
							font-weight: bold;
							flex: 1;
						}
					}
				}
			}
		}

		.cardData {
			display: flex;
			padding: 10px;
			align-items: center;

			.item {
				height: 80px;
				padding: 10px;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: space-evenly;
				background-color: #fff;
				margin-right: 10px;
				border-radius: 3px;
				border: 1px solid $tratercompositetopBlockBorderBack;
			}
		}

		// .averageInfos {
		// 	display: flex;
		// 	align-items: center;
		// 	margin-bottom: 5px;

		// 	.details {
		// 		display: flex;
		// 		margin-right: 20px;

		// 		.item {
		// 			padding: 10px;
		// 			background-color: #fff;
		// 			border: 1px solid #666;
		// 			display: flex;
		// 			flex-direction: column;
		// 			width: 100px;
		// 			margin-right: 5px;
		// 			border-radius: 3px;

		// 			span {
		// 				font-size: 12px;
		// 				white-space: nowrap;
		// 				display: flex;
		// 				justify-content: space-between;
		// 				align-items: center;

		// 				&:first-child {
		// 					margin-bottom: 6px;
		// 				}
		// 			}
		// 		}
		// 	}

		// 	.search {
		// 		// margin-right: 20px;

		// 		.section {
		// 			text-align: center;
		// 			font-size: 16px;
		// 			margin-bottom: 10px;
		// 		}

		// 		.seconds {
		// 			display: flex;
		// 			justify-content: space-between;
		// 			margin-bottom: 10px;

		// 			span {
		// 				cursor: pointer;
		// 				font-size: 14px;

		// 				&.on {
		// 					font-weight: bold;
		// 					color: $danger;
		// 				}

		// 			}
		// 		}

		// 		.times {
		// 			display: flex;
		// 			justify-content: space-between;
		// 			align-items: center;

		// 			span {
		// 				font-size: 16px;
		// 				padding: 0px 10px;
		// 				cursor: pointer;
		// 			}

		// 			div {
		// 				width: 80px;
		// 				padding: 5px;
		// 				border-radius: 4px;
		// 				text-align: center;
		// 				background-color: #fff;
		// 				font-size: 16px;
		// 			}
		// 		}
		// 	}
		// }
	}

	//排序
	.orderIcon {
		line-height: 0.6;
		cursor: pointer;
	}
</style>
